import React from "react";
import {Card, Link, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import CardContent from "@mui/material/CardContent";


const TestimonialCard = ({testimonial, className}) => {

    const useStyles = makeStyles((theme) => ({

    }));
    const classes = useStyles();

    return (
        <Card
            className={className}
            key={testimonial}
        >
            {testimonial.chefName && <CardContent>
                <Typography
                    variant="subtitle"
                    color="text.primary"
                >
                    <Link href={testimonial.chefLink}>Chef {testimonial.chefName}</Link>
                </Typography>
            </CardContent>}
            <CardContent>
                <Typography
                    variant="body1"
                    color="text.primary"
                >
                "{testimonial.text}"
                </Typography>
            </CardContent>
            <CardContent>
                <Typography
                    variant="subtitle2"
                    color="text.secondary"
                    fontStyle="italic"
                >
                    {testimonial.name}
                </Typography>
            </CardContent>

        </Card>
    )
}
export default TestimonialCard;