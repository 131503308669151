import { Button, Container, Typography, Box } from "@mui/material";
import React from "react";
import HowItWorks from "../chefSignUp/chefhowItWorks";
import ChefsWhy from "../chefSignUp/chefsWhy";

function ChefOnboardingIntro({ nextStep }) {
    return (
        <Container component="main" maxWidth="sm">
            <Box sx={{ my: 2 }}>
                <img
                    src="https://firebasestorage.googleapis.com/v0/b/chefsandhome.appspot.com/o/chef_signup_chef%20Large.jpeg?alt=media&token=17057288-4633-490d-8d4f-43beb9c17a4e"
                    alt="Chef"
                    style={{ width: '100%', height: 'auto', borderRadius: 4 }}
                />
            </Box>
            <ChefsWhy />
            <Button
                color="warning"
                variant="contained"
                fullWidth
                onClick={nextStep}
                sx={{ mb: 2, backgroundColor: "#F9A826" }}
            >
                Sign up now!
            </Button>
            <Box sx={{ my: 2 }}>
                <img
                    src="https://firebasestorage.googleapis.com/v0/b/chefsandhome.appspot.com/o/chef_signup_hands.jpeg?alt=media&token=bdf71adc-34e7-4796-ae78-e3f98743f248"
                    alt="Hands"
                    style={{ width: '100%', height: 'auto', borderRadius: 4 }}
                />
            </Box>
            <HowItWorks />
            <Button
                color="warning"
                variant="contained"
                fullWidth
                onClick={nextStep}
                sx={{ mb: 2, backgroundColor: "#F9A826" }}
            >
                Get Started
            </Button>
        </Container>
    );
}

export default ChefOnboardingIntro;