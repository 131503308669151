// Footer.js
import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import {Link} from "@mui/material";

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: "#f5f5f5", // Light grey color
    padding: theme.spacing(3),
    textAlign: "center",
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#f5f5f5",
    marginTop: "auto",
  },
}));

const Footer = () => {
  const classes = useStyles();
  const currentYear = new Date().getFullYear();
  return (
    <Box component="footer" className={classes.wrapper}>
      <div className={classes.wrapper}>
        {/*                <Typography variant="body2" color="black" className={classes.footer}>
                    &copy; {new Date().getFullYear()} Chefs and Home
                </Typography>*/}
        <Typography variant="body2" className={classes.footer}>
          <Link href={"/terms-of-service.html"}>Terms of Service</Link> | <Link href={"/privacy-policy.html"}>Privacy Policy</Link> | Copyright © {currentYear} Chefs & Home Inc. All rights reserved.
        </Typography>
      </div>
    </Box>
  );
};

export default Footer;
