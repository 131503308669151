import React, { Suspense, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import { auth } from "./firebase/initFirebase";
import AppBarComponent from "./components/utils/AppBar";
import "./App.css";
import Footer from "./components/utils/footer";
import { CircularProgress } from "@mui/material";
import ChefOnboardingForm from "./components/chefOnboardingV2/ChefOnboardingForm";
import StripeConnect from "./components/chefOnboardingV2/StripeConnect";
import TestimonialPage from "./components/testimonials/TestimonialPage";
import LogRocket from "logrocket";

// Components to be lazy loaded
const CommonQuestions = React.lazy(
  () => import("./components/home/commonQuestions"),
);
const About = React.lazy(() => import("./components/aboutUs/about"));
const ChefSearch = React.lazy(
  () => import("./components/MeetChefs/ChefSearch"),
);
const Login = React.lazy(() => import("./components/login/login"));
const Home = React.lazy(() => import("./components/home/Home"));
const Success = React.lazy(() => import("./components/success/Success"));
const Failure = React.lazy(() => import("./components/failure/Failure"));
const WithoutPaymentSuccess = React.lazy(
  () => import("./components/success/withoutPaymentSuccess"),
);
const MyProfile = React.lazy(() => import("./components/MyProfile/MyProfile"));
const ChefDetails = React.lazy(
  () => import("./components/MeetChefs/ChefDetials"),
);
const Loader = () => {
  return (
    <div className="home-container">
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress style={{ color: "#F9A826" }} />
      </div>
    </div>
  );
};
const AuthSkeleton = () => {
  return (
    <>
      <div className="home-container">
        <div
          style={{
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <AppBarComponent />
          <Routes>
            <Route
              path={"home"}
              element={
                <Suspense fallback={<Loader />}>
                  <Home />
                </Suspense>
              }
            />
              <Route path="/contactus" element={<Navigate replace to="/about" />} />
            <Route
              path={"about"}
              element={
                <Suspense fallback={<Loader />}>
                  <About />
                </Suspense>
              }
            />
            <Route
              path={"success/:session_id"}
              element={
                <Suspense fallback={<Loader />}>
                  <Success />
                </Suspense>
              }
            />
            <Route
              path={"order/:order_id"}
              element={
                <Suspense fallback={<Loader />}>
                  <WithoutPaymentSuccess />
                </Suspense>
              }
            />
            <Route
              path={"faq"}
              element={
                <Suspense fallback={<Loader />}>
                  <CommonQuestions />
                </Suspense>
              }
            />
            <Route
              path={"failure/:session_id"}
              element={
                <Suspense fallback={<Loader />}>
                  <Failure />
                </Suspense>
              }
            />
              <Route path="/chefsignup" element={<Navigate replace to="/new-chef" />} />
              <Route
              path={"new-chef"}
              element={
                  <Suspense fallback={<Loader />}>
                      <ChefOnboardingForm />
                  </Suspense>
              }
            />
            <Route
              path={"new-chef/stripe-refresh"}
              element={
                  <Suspense fallback={<Loader />}>
                      <StripeConnect refresh={true} />
                  </Suspense>
              }
            />
            <Route
              path={"new-chef/stripe-return"}
              element={
                  <Suspense fallback={<Loader />}>
                      <StripeConnect />
                  </Suspense>
              }
            />
            <Route
              path={"chef/:chef_id"}
              element={
                <Suspense fallback={<Loader />}>
                  <ChefDetails />
                </Suspense>
              }
            />
            <Route
              path={"chefs"}
              element={
                <Suspense fallback={<Loader />}>
                  <ChefSearch />
                </Suspense>
              }
            />
            <Route
              path={"testimonials"}
              element={
                  <Suspense fallback={<Loader />}>
                      <TestimonialPage />
                  </Suspense>
              }
            />
            <Route
              path={"profile/*"}
              element={
                <Suspense fallback={<Loader />}>
                  <MyProfile />
                </Suspense>
              }
            />
            <Route
              index
              element={
                <Suspense fallback={<Loader />}>
                  <Home />
                </Suspense>
              }
            />
          </Routes>
          <Footer></Footer>
        </div>
      </div>
    </>
  );
};

const LayoutSkeleton = () => {
  return (
    <>
      <div className="home-container">
        <AppBarComponent />
        <Routes>
          <Route
            path={"home"}
            element={
              <Suspense fallback={<Loader />}>
                <Home />
              </Suspense>
            }
          />
            <Route path="/contactus" element={<Navigate replace to="/about" />} />
          <Route
            path={"about"}
            element={
              <Suspense fallback={<Loader />}>
                <About />
              </Suspense>
            }
          />
            <Route path="/chefsignup" element={<Navigate replace to="/new-chef" />} />
            <Route
                path={"new-chef"}
                element={
                    <Suspense fallback={<Loader />}>
                        <ChefOnboardingForm />
                    </Suspense>
                }
            />
            <Route
                path={"new-chef/stripe-refresh"}
                element={
                    <Suspense fallback={<Loader />}>
                        <StripeConnect refresh={true} />
                    </Suspense>
                }
            />
            <Route
                path={"new-chef/stripe-return"}
                element={
                    <Suspense fallback={<Loader />}>
                        <StripeConnect refresh={false}/>
                    </Suspense>
                }
            />
          <Route
            index
            element={
              <Suspense fallback={<Loader />}>
                <Home />
              </Suspense>
            }
          />
          <Route
            path={"chefs"}
            element={
              <Suspense fallback={<Loader />}>
                <ChefSearch />
              </Suspense>
            }
          />
          <Route
            path={"testimonials"}
            element={
              <Suspense fallback={<Loader />}>
                <TestimonialPage />
              </Suspense>
            }
          />
          <Route
            path={"faq"}
            element={
              <Suspense fallback={<Loader />}>
                <CommonQuestions />
              </Suspense>
            }
          />
          <Route
            path={"chef/:chef_id"}
            element={
              <Suspense fallback={<Loader />}>
                <ChefDetails />
              </Suspense>
            }
          />
        </Routes>
        <Footer></Footer>
      </div>
    </>
  );
};

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    return auth.onAuthStateChanged((user) => {
      setUser(user);
      if (user && user.uid) {
          LogRocket.identify(user.uid);
      }
      setLoading(false);
    });
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <Router>
      <Routes>
        <Route
          path="/login"
          element={
            <Suspense fallback={<Loader />}>
              {user ? <Navigate to="/home" /> : <Login />}
            </Suspense>
          }
        />
        <Route
          path="/*"
          element={
            user ? (
              <AuthSkeleton />
            ) : (
              <Suspense fallback={<Loader />}>
                <LayoutSkeleton />
              </Suspense>
            )
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
