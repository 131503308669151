import React from 'react';
import { Typography, Card, CardContent, Box } from "@mui/material";
import {
    Timeline,
    TimelineConnector,
    TimelineContent,
    TimelineItem,
    timelineItemClasses,
    TimelineSeparator
} from "@mui/lab";
import Divider from "@mui/material/Divider";

const HowItWorks = () => {
    const stepStyle = {
        fontSize: '2rem',
        fontWeight: 'bold',
        color: '#000000',
        marginRight: '1rem',
    };

    const cardStyle = {
        border: '1px solid #F9A826',
        maxWidth: 400, // Adjust the max width as needed
        backgroundColor: 'transparent',
    };

    const centerTimelineStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    };

    return (
        <>
            <Box my={0}>
                <Typography fontFamily={'DM Serif Text, sans-serif'} variant="h4" align="center" gutterBottom>
                    How It Works
                </Typography>

                <div style={centerTimelineStyle}>
                    <Timeline sx={{
                        [`& .${timelineItemClasses.root}:before`]: {
                            flex: 0,
                            padding: 0,
                        },
                    }}>
                        {/* Step 1 */}
                        <TimelineItem>
                            <TimelineSeparator>
                                <div style={stepStyle}>1</div>
                                <TimelineConnector style={{ backgroundColor: '#F9A826' }} />
                            </TimelineSeparator>
                            <TimelineContent>
                                <Card sx={cardStyle}>
                                    <CardContent>
                                        <Typography fontWeight={'bold'} variant="h7" gutterBottom>
                                            Sign up
                                        </Typography>
                                        <Divider sx={{color:'#F9A826' }} />
                                        <Typography  variant="h8" gutterBottom>
                                            We'll collect a few things from you:
                                            <li>- Personal Details</li>
                                            <li>- Professional Details</li>
                                            <li>- Banking and tax details (via our secure partner)</li>
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </TimelineContent>
                        </TimelineItem>

                        {/* Step 2 */}
                        <TimelineItem>
                            <TimelineSeparator>
                                <div style={stepStyle}>2</div>
                                <TimelineConnector style={{ backgroundColor: '#F9A826' }} />
                            </TimelineSeparator>
                            <TimelineContent>
                                <Card sx={cardStyle}>
                                    <CardContent>
                                        <Typography fontWeight={'bold'} variant="h7" gutterBottom>
                                            Have a chat with us
                                        </Typography>
                                        <Divider sx={{color:'#F9A826' }} />
                                        <Typography  variant="h8" gutterBottom>
                                            We will follow up with a few questions regarding your expertise, expectations, weekly availability and locations you would like to service                                        </Typography>
                                    </CardContent>
                                </Card>
                            </TimelineContent>
                        </TimelineItem>

                        {/* Step 3 */}
                        <TimelineItem>
                            <TimelineSeparator>
                                <div style={stepStyle}>3</div>
                                <TimelineConnector style={{ backgroundColor: '#F9A826' }} />
                            </TimelineSeparator>
                            <TimelineContent>
                                <Card sx={cardStyle}>
                                    <CardContent>
                                        <Typography variant="h7" fontWeight={'bold'} gutterBottom>
                                            Match with our customers
                                        </Typography>
                                        <Divider sx={{color:'#F9A826' }} />
                                        <Typography  variant="h8" gutterBottom>
                                            Customers who are looking for your expertise will directly place a booking request on our website.                                        </Typography>
                                    </CardContent>
                                </Card>
                            </TimelineContent>
                        </TimelineItem>

                        {/* Step 4 */}
                        <TimelineItem>
                            <TimelineSeparator>
                                <div style={stepStyle}>4</div>
                            </TimelineSeparator>
                            <TimelineContent>
                                <Card sx={cardStyle}>
                                    <CardContent>
                                        <Typography fontWeight={'bold'} variant="h7" gutterBottom>
                                            Cook great meals at customers' homes and get paid!
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </TimelineContent>
                        </TimelineItem>
                    </Timeline>
                </div>
            </Box>
        </>
    );
};

export default HowItWorks;
