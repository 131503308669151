import React from 'react';
import { Typography, Box } from "@mui/material";

const WhyChefsAndHome = () => {
    const orangeColor = '#F9A826';

    const reasonContainerStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '1rem',
        borderRadius: '5px',
        backgroundColor: 'white',
        boxShadow: `0px 2px 4px ${orangeColor}`, // Use the specified orange color for shadow
        border: `1px solid ${orangeColor}`, // Use the specified orange color for the border
        minHeight: '200px',
        maxWidth:'600px'
    };

    const textContainerStyle = {
        textAlign: 'justify',
        marginTop: '0.5rem',
    };

    const headingStyle = {
        fontSize: '1.3rem',

        fontFamily:'DM Serif Text, sans-serif'
    };

    const listItemStyle = {
        listStyleType: 'disc',
        marginLeft: '20px',
    };

    const mainTextStyle = {
        lineHeight: '1.5',
    };

    return (
        <Box p={0} paddingY={2}>
            <div style={reasonContainerStyle}>
                <Typography variant="h6" style={headingStyle}>
                    Do you love cooking but...
                </Typography>
                <div style={textContainerStyle}>
                    <ul style={listItemStyle}>
                        <li>You are tired of the long shifts at the restaurants?</li>
                        <li>You are underpaid?</li>
                        <li>Don't know where to find customers who will appreciate your craft?</li>
                    </ul>
                </div>
                <div style={textContainerStyle}>
                    <Typography style={mainTextStyle}>
                        Then Chefs and Home is the platform for you! Make extra income by joining us and cooking healthy delicious food a few hours every week.<br/><br/> With Chefs and Home, you are joining a growing customer base that wants to eat home-cooked food daily. You are your own Boss… You decide your hourly rate, working hours, and service locations.
                    </Typography>
                </div>
            </div>
        </Box>
    );
};

export default WhyChefsAndHome;
