
const chefOnboardingFormData = {

}

const chefOnboardingFormLabels = {
    name: "Name",
    email: "Email Address",
    phone: "Phone Number",
    address: "Address",
    cuisines: "Cuisines",
    customCuisines: "Other Cuisines",
    yearsExperience: "Years of Experience",
    languages: "Languages",
    pronouns: "Pronouns",
    about: "Bio",
    petComfort: "Comfortable with pets?",
    chargeRate: "Rate",
    profilePicture: "Profile Picture",
    consentBackgroundCheck: "Consented to a background check",
    customLanguages: "Other languages"
}

export default chefOnboardingFormLabels;