import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import LogRocket from 'logrocket';

LogRocket.init('lal37c/chefs-and-home');
const theme = createTheme();
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
      <ThemeProvider theme={theme}>
          <App />
      </ThemeProvider>
);

function sendVitalsToGoogleAnalytics({ name, delta, id }) {
    if (window.gtag) {
        window.gtag('event', name, {
            event_category: 'Web Vitals',
            value: Math.round(name === 'CLS' ? delta * 1000 : delta),
            event_label: id,
            non_interaction: true,
        });
    }
}

reportWebVitals(sendVitalsToGoogleAnalytics);
