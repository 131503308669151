// noinspection DuplicatedCode

import React, { useEffect, useRef, useState } from "react";

import TextField from "@mui/material/TextField";

const apiKey = process.env.REACT_APP_GOOGLE_PLACES_API;
const mapApiJs = "https://maps.googleapis.com/maps/api/js";

function loadAsyncScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    Object.assign(script, {
      type: "text/javascript",
      async: true,
      src,
    });
    script.addEventListener("load", () => resolve(script));
    document.head.appendChild(script);
  });
}

const extractAddress = (place) => {
  const address = {
    streetNumber: "",
    street: "",
    city: "",
    state: "",
    zip: "",
    country: "",
    subpremise: "",
    plain() {
      // Construct the initial part of the address with conditional formatting for subpremise
      let baseAddress = `${this.streetNumber ? this.streetNumber + " " : ""}${this.street ? this.street : ""}`;
      if (this.subpremise) {
        if (/\d/.test(this.subpremise)) {
          // If subpremise contains any number, we consider it might need special formatting
          baseAddress += this.subpremise.match(/^\d+$/) ? ` #${this.subpremise}` : ` ${this.subpremise}`;
        } else {
          // If subpremise is purely textual, append directly
          baseAddress += ` ${this.subpremise}`;
        }
      }

      const location = [this.city, this.state, this.zip].filter(part => part).join(", ");
      return `${baseAddress}, ${location} ${this.country}`.trim();
    },
    toStripeFormat() {
      let line1 = `${this.streetNumber ? this.streetNumber + " " : ""}${this.street ? this.street : ""}`;
      let line2 = this.subpremise ? this.subpremise : "";

      return {
        line1: line1.trim(),
        line2: line2.trim(),
        city: this.city,
        state: this.state,
        postal_code: this.zip,
        country: "US"
      }
    },
  };

  if (!Array.isArray(place?.address_components)) {
    return address;
  }

  place.address_components.forEach((component) => {
    const types = component.types;
    const value = component.long_name;
    if (types.includes("street_number")) {
      address.streetNumber = value;
    }

    if (types.includes("route")) {
      address.street = value;
    }

    if (types.includes("subpremise")) {
      address.subpremise = value;
    }

    if (types.includes("locality")) {
      address.city = value;
    }

    if (types.includes("administrative_area_level_1")) {
      address.state = value;
    }

    if (types.includes("postal_code")) {
      address.zip = value;
    }

    if (types.includes("country")) {
      address.country = value;
    }
  });

  return address;
};

const AddressTextField = ({ initialValue, onAddressChange, error, helperText, color }) => {
  const searchInput = useRef(null);
  const [address, setAddress] = useState({
    city: "",
    state: "",
    zip: "",
    country: "",
    plain() {
      const city = this.city ? this.city + ", " : "";
      const zip = this.zip ? this.zip + ", " : "";
      const state = this.state ? this.state + ", " : "";
      return city + zip + state + this.country;
    },
  });

  const [searchValue, setSearchValue] = useState(
    window.location.pathname !== "chefsignup" ||
      window.location.pathname !== "admin"
      ? initialValue && Object.keys(initialValue).length > 0
        ? initialValue
        : ""
      : "",
  );

  const initMapScript = () => {
    if (window.google) {
      return Promise.resolve();
    }
    const src = `${mapApiJs}?key=${apiKey}&libraries=places&v=weekly`;
    return loadAsyncScript(src);
  };

  const onChangeAddress = (autocomplete) => {
    const place = autocomplete.getPlace();
    const updatedAddress = extractAddress(place);
    setAddress(updatedAddress);
    // console.log(updatedAddress.plain())
    setSearchValue(updatedAddress.plain());
    onAddressChange(updatedAddress, updatedAddress.plain());
  };

  const initAutocomplete = () => {
    if (!searchInput.current) return;

    const autocomplete = new window.google.maps.places.Autocomplete(
      searchInput.current,
      {
        componentRestrictions: { country: "US" },
      },
    );
    autocomplete.setFields(["address_components", "geometry"]);
    autocomplete.addListener("place_changed", () =>
      onChangeAddress(autocomplete),
    );
  };

  useEffect(() => {
    initMapScript().then(() => initAutocomplete());
  }, []);

  return (
    <TextField
      label="* Address"
      type={"text"}
      color={color? color : "warning"}
      fullWidth
      multiline
      rows={2}
      value={searchValue}
      onChange={(e) => setSearchValue(e.target.value)}
      variant="outlined"
      error={error}
      helperText={helperText}
      style={{
        borderColor: "black",
        backgroundColor: "white",
        fontSize: "1.2rem",
        marginBottom: "25px"
      }}
      sx={{ mt: 2 }}
      inputRef={searchInput}
    />
  );
};

export default AddressTextField;
