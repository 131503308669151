import React, { useState } from 'react';

const ShutdownModal = () => {
    const [isVisible, setIsVisible] = useState(true);

    const closeModal = () => setIsVisible(false);

    const handleWaitlistSignup = () => {
        window.location.href = 'https://wait.vivachefs.com';
    };

    if (!isVisible) return null;

    return (
        <div style={overlayStyle}>
            <div style={modalStyle}>
                <button style={closeButtonStyle} onClick={closeModal}>
                    &times;
                </button>
                <h2 style={headingStyle}>Coming Soon!</h2>
                <div style={logoContainerStyle}>
                    <img
                        src="/apple-touch-icon.png"
                        alt="Chefs and Home Logo"
                        style={logoStyle}
                    />
                    <span style={arrowStyle}>→</span>
                    <img
                        src="/viva-v.png"
                        alt="Viva Chefs Logo"
                        style={logoStyle}
                        height="75"
                    />
                </div>
                <p style={paragraphStyle}>
                    After cooking over <strong>15,000 meals</strong>, we're making some changes! Chefs and Home will soon be{' '}
                    <strong>Viva Chefs</strong>. Sign up for our waitlist to be the first to know.
                </p>
                <button
                    style={waitlistButtonStyle}
                    onMouseEnter={(e) =>
                        (e.currentTarget.style.backgroundColor = waitlistButtonHoverStyle.backgroundColor)
                    }
                    onMouseLeave={(e) =>
                        (e.currentTarget.style.backgroundColor = waitlistButtonStyle.backgroundColor)
                    }
                    onClick={handleWaitlistSignup}
                >
                    Sign Up
                </button>
            </div>
        </div>
    );
};

// Styles
const overlayStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1000,
};

const modalStyle = {
    position: 'relative',
    width: '90%',
    maxWidth: '600px',
    backgroundColor: '#fff',
    padding: '20px 30px',
    borderRadius: '8px',
    textAlign: 'center',
    fontFamily: "'Open Sans', sans-serif",
    boxSizing: 'border-box',
};

const headingStyle = {
    fontSize: '26px',
    marginBottom: '20px',
    color: '#333',
    fontWeight: '700',
};

const logoContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    marginBottom: '20px',
};

const logoStyle = {
    width: '75px',
    height: '75px',
    margin: '0 10px',
    objectFit: 'contain',
};

const arrowStyle = {
    fontSize: '36px',
    margin: '0 10px',
    color: '#333',
};

const paragraphStyle = {
    fontSize: '15px',
    lineHeight: '1.5',
    color: '#555',
};

const closeButtonStyle = {
    position: 'absolute',
    top: '10px',
    right: '15px',
    background: 'none',
    border: 'none',
    fontSize: '24px',
    cursor: 'pointer',
    color: '#aaa',
    transition: 'color 0.3s',
};

const waitlistButtonStyle = {
    backgroundColor: '#D43D88',
    color: '#fff',
    padding: '12px 24px',
    border: 'none',
    borderRadius: '5px',
    fontSize: '16px',
    cursor: 'pointer',
    marginTop: '20px',
    fontWeight: 'bold',
    transition: 'background-color 0.3s',
};

const waitlistButtonHoverStyle = {
    backgroundColor: '#CF2814',
};

export default ShutdownModal;