import {Card, Link, Paper} from "@mui/material";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import React from "react";
import {makeStyles} from "@mui/styles";
import {testimonialStore} from "./TestimonialStore";
import TestimonialCard from "./TestimonialCard";
import {googleReviewLink} from './TestimonialStore'

const TestimonialPage = () => {

    const useStyles = makeStyles((theme) => ({
        banner: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "24px",
            margin: "12px",
            boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.1)",
            borderRadius: "12px",
            [theme.breakpoints.down("sm")]: {
                width: "80%",
            },
        },
        tCard: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: 700,
            padding: "24px",
            marginTop: "4vh",
            marginBottom: "2vh",
            margin: "auto",
            boxShadow: "0px 0px 40px rgba(0, 0, 0, 0.2)",
            borderRadius: "12px",
            whiteSpace: "pre-wrap",
            [theme.breakpoints.down("sm")]: {
                width: "80%",
            },
        },
    }));

    const classes = useStyles();
    const testimonials = testimonialStore.map(
        (t) => <TestimonialCard className={classes.tCard} testimonial={t}/>
    );

    return (
        <>

            <Paper className={classes.banner}>
            <Typography variant={"h5"} fontWeight={"bold"}>
                Hear from our customers
            </Typography>
            <Divider
                style={{
                    color: "#F9A826",
                    width: "100%",
                    margin: "14px 0px",
                    backgroundColor: "#d98300",
                }}
                textAlign="left"
            />
                <div>
                    {testimonials}
                </div>
                <Card className={classes.tCard}>
                    <Link href={googleReviewLink}>
                        See more of our reviews on Google.
                    </Link>
                </Card>
            </Paper>
        </>
    );
};

export default TestimonialPage;