import React, {useEffect} from 'react';
import {useSearchParams} from "react-router-dom";
import {CircularProgress, Container, Link, Paper, Typography} from "@mui/material";
import {refreshStripeAccountLink} from "../../firebase/initFirebase";

function StripeConnect({refresh}) {

    let effectCalled = false;
    const searchParams = useSearchParams()[0];
    useEffect(() => {
        const refreshUrl = async () => {
            const stripeId = searchParams.get("stripe_id");
            const resp = await refreshStripeAccountLink(stripeId);
            console.log(resp);
            window.location.href = resp.url;
        };

        if (refresh){
            refreshUrl();
        }
    }, [refresh, searchParams]);

    const contactInfo = "contact@chefsandhome.com";
    if (refresh) {
        return <CircularProgress />;
    }
    return (
        <Container component="main" maxWidth="sm">
            <Paper style={{ padding: '20px', marginTop: '20px' }}>
                <Typography variant="h5" component="h1" gutterBottom>
                    Thank you!
                </Typography>
                <Typography>
                    We'll be in touch soon. We'll be looking for the following:
                    <li>A background check</li>
                    <li>Profile picture</li>
                    <li>Where you're willing to travel</li>
                    <li>Your menu</li>
                    <li>Your rate</li>
                    <li>Your availability</li>
                    If you have any questions, reach out at <Link href={"mailto:" + contactInfo}>{contactInfo}</Link>
                </Typography>
            </Paper>
        </Container>
    );
}

export default StripeConnect;
