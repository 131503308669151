import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
    TextField,
    Button,
    Typography,
    Container,
    MenuItem,
    FormControl,
    InputLabel,
    Select,
    OutlinedInput,
    Chip,
    FormHelperText
} from '@mui/material';
import {Link, useNavigate} from 'react-router-dom';
import chefOnboardingFormLabels from "./chefOnboardingFormLabels";

// Validation Schema using Yup
const validationSchema = yup.object().shape({
    cuisines: yup.array().of(yup.string()).min(1, "Please select at least one cuisine"),
    customCuisines: yup.string().when('cuisines', (cuisines, schema) => {
        return cuisines.flat().includes("Other")
            ? schema.required('Please include your other cuisines')
            : schema.notRequired();
    }),
    yearsExperience: yup.number().positive('Enter a valid number of years').required('Years of experience is required'),
    languages: yup.array().of(yup.string()).min(1, 'Please select at least one language'),
    customLanguages: yup.string().when('languages', (languages, schema) => {
        return languages.includes('Other')
            ? schema.required('Please specify the language(s)')
            : schema.notRequired();
    }),
    petComfort: yup.string().required("Please explain if you're comfortable with pets"),
});

function ProfessionalDetails({ formData, setFormData, nextStep, prevStep }) {
    const navigate = useNavigate();
    const formik = useFormik({
        initialValues: {
            ...formData,
            cuisines: formData.cuisines? formData.cuisines: [],
            customCuisines: formData.customCuisines? formData.customCuisines: '',
            yearsExperience: formData.yearsExperience? formData.yearsExperience: '',
            languages: formData.languages? formData.languages: [],
            customLanguages: formData.customLanguages? formData.customLanguages: '',
            petComfort: formData.petComfort? formData.petComfort: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            setFormData(values);
            nextStep();
        },
    });


    return (
        <Container component="main" maxWidth="xs">
            <Typography component="h1" variant="h5">
                Professional Details
            </Typography>
            <form onSubmit={formik.handleSubmit}>
                <FormControl fullWidth margin="normal">
                    <InputLabel id="cuisines-label">{"* " + chefOnboardingFormLabels.cuisines}</InputLabel>
                    <Select
                        labelId="cuisines-label"
                        id="cuisines"
                        name="cuisines"
                        multiple
                        value={formik.values.cuisines}
                        onChange={formik.handleChange}
                        input={<OutlinedInput id="select-multiple-chip" label={chefOnboardingFormLabels.cuisines} />}
                        renderValue={(selected) => (
                            <div>
                                {selected.map((value) => (
                                    <Chip key={value} label={value} style={{ margin: 2 }} />
                                ))}
                            </div>
                        )}
                        error={formik.touched.cuisines && Boolean(formik.errors.cuisines)}
                    >
                        <MenuItem value="North Indian">North Indian</MenuItem>
                        <MenuItem value="South Indian">South Indian</MenuItem>
                        <MenuItem value="American">American</MenuItem>
                        <MenuItem value="Italian">Italian</MenuItem>
                        <MenuItem value="Chinese">Chinese</MenuItem>
                        <MenuItem value="Nepalese">Nepalese</MenuItem>
                        <MenuItem value="Mexican">Mexican</MenuItem>
                        <MenuItem value="Korean">Korean</MenuItem>
                        <MenuItem value="Thai">Thai</MenuItem>
                        <MenuItem value="Japanese">Japanese</MenuItem>
                        <MenuItem value="Other">Other</MenuItem>
                    </Select>
                    <FormHelperText>
                        {formik.errors.cuisines ? formik.errors.cuisines : "Select all cuisines you know how to cook"}
                    </FormHelperText>
                </FormControl>
                {formik.values.cuisines.includes('Other') && (
                    <TextField
                        fullWidth
                        id="customCuisines"
                        name="customCuisines"
                        label={"* " + chefOnboardingFormLabels.customCuisines}
                        value={formik.values.customCuisines}
                        onChange={formik.handleChange}
                        error={formik.touched.customCuisines && Boolean(formik.errors.customCuisines)}
                        helperText={formik.touched.customCuisines && formik.errors.customCuisines}
                        margin="normal"
                    />
                )}
                <FormControl fullWidth margin="normal" error={Boolean(formik.touched.languages && formik.errors.languages)}>
                    <InputLabel id="languages-label">{"* " + chefOnboardingFormLabels.languages}</InputLabel>
                    <Select
                        labelId="languages-label"
                        id="languages"
                        name="languages"
                        multiple
                        value={formik.values.languages}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}  // Make sure to handle blur to mark as touched
                        input={<OutlinedInput label={chefOnboardingFormLabels.languages} />}
                        renderValue={(selected) => (
                            <div>
                                {selected.map((value) => (
                                    <Chip key={value} label={value} style={{ margin: 2 }} />
                                ))}
                            </div>
                        )}
                    >
                        <MenuItem value="English">English</MenuItem>
                        <MenuItem value="Spanish">Spanish</MenuItem>
                        <MenuItem value="French">French</MenuItem>
                        <MenuItem value="Hindi">Hindi</MenuItem>
                        <MenuItem value="Tamil">Tamil</MenuItem>
                        <MenuItem value="Other">Other</MenuItem>
                    </Select>
                    <FormHelperText>
                        {formik.errors.languages ? formik.errors.languages : "Select all languages you are fluent in."}
                    </FormHelperText>
                </FormControl>
                {formik.values.languages.includes('Other') && (
                    <TextField
                        fullWidth
                        id="customLanguages"
                        name="customLanguages"
                        label={"* " + chefOnboardingFormLabels.customLanguages}
                        value={formik.values.customLanguages}
                        onChange={formik.handleChange}
                        error={formik.touched.customLanguages && Boolean(formik.errors.customLanguages)}
                        helperText={formik.touched.customLanguages && formik.errors.customLanguages}
                        margin="normal"
                    />
                )}

                <TextField
                    fullWidth
                    id="yearsExperience"
                    name="yearsExperience"
                    label={"* " + chefOnboardingFormLabels.yearsExperience}
                    type="number"
                    value={formik.values.yearsExperience}
                    onChange={formik.handleChange}
                    error={formik.touched.yearsExperience && Boolean(formik.errors.yearsExperience)}
                    helperText={formik.touched.yearsExperience && formik.errors.yearsExperience}
                    margin="normal"
                />
                <FormControl fullWidth margin="normal" error={Boolean(formik.touched.petComfort && formik.errors.petComfort)}>
                    <InputLabel id="pet-comfort-label">{"* " + chefOnboardingFormLabels.petComfort}</InputLabel>
                    <Select
                        labelId="pet-comfort-label"
                        id="pet-comfort"
                        name="petComfort"
                        value={formik.values.petComfort}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur} // Ensuring the field is marked as touched on blur
                        label={chefOnboardingFormLabels.petComfort}
                    >
                        <MenuItem value="Highly Comfortable">Very comfortable with pets</MenuItem>
                        <MenuItem value="Comfortable">Okay with pets</MenuItem>
                        <MenuItem value="Not Comfortable">Do not want to work in a home with pets</MenuItem>
                    </Select>
                    <FormHelperText>{formik.touched.petComfort && formik.errors.petComfort}</FormHelperText>
                </FormControl>

                <Button color="primary" variant="contained" fullWidth type="submit" sx={{ mb: 2 }}>
                    Next
                </Button>
                <Button onClick={prevStep} color="secondary" variant="contained" fullWidth >
                    Back
                </Button>
            </form>
        </Container>
    );
}

export default ProfessionalDetails;
