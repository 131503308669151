import React from 'react';
import {Button, Container, Link, List, ListItem, ListItemText, Paper, Typography} from "@mui/material";
import chefOnboardingFormLabels from "./chefOnboardingFormLabels";

function Confirmation({ formData, prevStep, nextStep }) {
    return (
        <Container component="main" maxWidth="sm">
            <Paper style={{ padding: '20px', marginTop: '20px' }}>
                <Typography variant="h5" component="h1" gutterBottom>
                    Please confirm your details before proceeding
                </Typography>
                <List>
                    {Object.entries(formData).map(([key, value]) => (value && (value.length !== undefined? value.length > 0 : true) &&
                        <ListItem key={key}>
                            <ListItemText
                                primary={chefOnboardingFormLabels[key]}
                                secondary={Array.isArray(value) ? value.join(', ') : value}
                            />
                        </ListItem>
                    ))}
                </List>
                <Typography variant={"caption"}>
                    By pressing "Submit" you agree to our <Link href={"/terms-of-service.html"}>Terms of Service</Link>
                </Typography>
                <div style={{paddingTop: "3px"}}>
                    <Button variant="contained" color="primary" onClick={() => nextStep()}>Submit</Button>
                    <Button variant="outlined" color="secondary" onClick={() => prevStep()} style={{ marginLeft: '10px' }}>
                        Back
                    </Button>
                </div>
            </Paper>
        </Container>
    );
}

export default Confirmation;
