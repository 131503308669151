import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
    TextField,
    Button,
    Typography,
    Container,
    FormControl,
    InputLabel,
    Select,
    OutlinedInput,
    Chip, MenuItem
} from '@mui/material';
import {Link, useNavigate} from 'react-router-dom';
import AddressTextField from "../orderCard/addressAutoComplete";
import chefOnboardingFormLabels from "./chefOnboardingFormLabels";

// Regular expression to validate standard US phone number format
const phoneRegExp = /^(\+\d{1,2}\s?)?(\(\d{3}\)|\d{3})[-.\s]?\d{3}[-.\s]?\d{4}$/;

const validationSchema = yup.object({
    name: yup.string().required('Name is required'),
    email: yup.string().email('Enter a valid email').required('Email is required'),
    phone: yup.string().matches(phoneRegExp, 'Phone number is not valid').required('Phone number is required'),
    address: yup.string().required('Address is required')
});

function PersonalDetails({ formData, setFormData, prevStep, nextStep, updateAddress }) {
    const navigate = useNavigate();
    const formik = useFormik({
        initialValues: formData,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            setFormData(values);
            nextStep();
        },
    });

    const handleAddressChange = (address) => {
        setFormData((prevState) => ({
            ...prevState,
            address: address,
        }));
        updateAddress(address);
        formik.setFieldValue('address', address.plain());
    };

    return (
        <Container component="main" maxWidth="xs">
            <Typography component="h1" variant="h5">
                Personal Details
            </Typography>
            <form onSubmit={formik.handleSubmit}>
                <TextField
                    fullWidth
                    id="name"
                    name="name"
                    label={"* " + chefOnboardingFormLabels.name}
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                    margin="normal"
                />
                <FormControl fullWidth margin="normal">
                    <InputLabel id="pronouns-label">{chefOnboardingFormLabels.pronouns}</InputLabel>
                    <Select
                        labelId="pronouns-label"
                        id="pronouns"
                        name="pronouns"
                        label={chefOnboardingFormLabels.pronouns}
                        value={formik.values.pronouns}
                        onChange={formik.handleChange}
                    >
                        <MenuItem value="He/him/his">He/him</MenuItem>
                        <MenuItem value="She/her/hers">She/her</MenuItem>
                        <MenuItem value="They/them/theirs">They/them</MenuItem>
                        <MenuItem value={formik.values.name? formik.values.name : "name"}>{formik.values.name ? formik.values.name: "Your name"}</MenuItem>
                        <MenuItem value="other">Other</MenuItem>
                    </Select>
                </FormControl>
                <TextField
                    fullWidth
                    id="email"
                    name="email"
                    label={"* " + chefOnboardingFormLabels.email}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                    margin="normal"
                />
                <TextField
                    fullWidth
                    id="phone"
                    name="phone"
                    label={"* " + chefOnboardingFormLabels.phone}
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                    error={formik.touched.phone && Boolean(formik.errors.phone)}
                    helperText={formik.touched.phone && formik.errors.phone}
                    margin="normal"
                />

                <AddressTextField
                    initialValue={formik.values.address}
                    onAddressChange={handleAddressChange}
                    error={formik.touched.address && Boolean(formik.errors.address)}
                    helperText={formik.touched.address && formik.errors.address}
                    color="primary"
                />

                <Button color="primary" variant="contained" fullWidth type="submit" sx={{ mb: 2 }}>
                    Next
                </Button>

                <Button onClick={prevStep} color="secondary" variant="contained" fullWidth >
                    Back
                </Button>
            </form>
        </Container>
    );
}

export default PersonalDetails;
