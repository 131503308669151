import React, { useState } from 'react';
import { Container, Typography, Button } from '@mui/material';
import PersonalDetails from './PersonalDetails';
import ProfessionalDetails from './ProfessionalDetails';
import Confirmation from './Confirmation';
import SubmitAndRedirect from "./SubmitAndRedirect";
import ChefOnboardingIntro from "./ChefOnboardingIntro";

function ChefOnboardingForm() {
    const [step, setStep] = useState(0);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        address: '',
        cookingType: '',
        yearsExperience: '',
        languages: [],
        cuisines: [],
        pronouns: '',
        about: '',
        petComfort: '',
        profilePicture: null,
    });
    const [address, setAddress] = useState({});

    const nextStep = () => setStep(step + 1);
    const prevStep = () => setStep(step - 1);

    switch(step) {
        case 0:
            return <ChefOnboardingIntro nextStep={nextStep} />
        case 1:
            return <PersonalDetails formData={formData} setFormData={setFormData} prevStep={prevStep} nextStep={nextStep} updateAddress={setAddress} />;
        case 2:
            return <ProfessionalDetails formData={formData} setFormData={setFormData} nextStep={nextStep} prevStep={prevStep} />;
        case 3:
            return <Confirmation formData={formData} prevStep={prevStep} nextStep={nextStep} address={address} />;
        case 4:
            return <SubmitAndRedirect formData={formData} address={address} />;

        default:
            return <Typography>Error: This step does not exist.</Typography>;
    }
}

export default ChefOnboardingForm;
