import React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import { auth } from "../../firebase/initFirebase";
import { useLocation, useNavigate } from "react-router-dom";

const MenuComponent = ({ anchorEl, onClose }) => {
  const nav = useNavigate();
  const location = useLocation();

  const menuItems = [
    { path: "/home", label: "Home", condition: location.pathname !== "/" && location.pathname !== "/home" },
    { path: "/profile", label: "My Profile", condition: auth.currentUser && location.pathname !== "/profile" },
    { path: "/chefs", label: "Meet our Chefs", condition: location.pathname !== "/chefs" },
    { path: "/testimonials", label: "Testimonials", condition: location.pathname !== "/testimonials" },
    { path: "/about", label: "About Us", condition: location.pathname !== "/about" },
    { path: "/faq", label: "FAQs", condition: location.pathname !== "/faq" },
  ];

  const navigateAndClose = (path) => {
    nav(path);
    onClose();
  };

  const handleSignOut = () => {
    auth.signOut()
        .then(() => navigateAndClose("/home"))
        .catch((error) => console.error("Sign-out error:", error));
  };

  return (
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={onClose}>
        {menuItems.map((item, index) => item.condition && [
          <MenuItem key={`item-${index}`} onClick={() => navigateAndClose(item.path)}>{item.label}</MenuItem>,
          <Divider key={`divider-${index}`} />
        ])}
        {auth.currentUser ? (
            <MenuItem onClick={handleSignOut} key="signout">Sign Out</MenuItem>
        ) : (
            <MenuItem onClick={() => navigateAndClose("/login")} key="login">Log In</MenuItem>
        )}
      </Menu>
  );
};

export default MenuComponent;
