class Testimonial {
    constructor(name, text, chefName, chefLink) {
        this.name = name;
        this.text = text;
        this.chefName = chefName;
        this.chefLink = chefLink;
    }
}
const testimonialStore = [
    new Testimonial(
        "Natalie",
        "Great food. I'll never stop using this service. Like, ever"
    ),
    new Testimonial(
        "Tarushree",
        "Yay! I have been a user and the chef did a great job for us in Sunnyvale. I now use this service weekly. Can definitely vouch for them.",
        ),
    new Testimonial(
        "Debjani",
        "Have been using this service for the past month and they have been great! Always very communicative and able to accomodate for changes in plans and dietary preferences. Rates are also reasonable and the chef they assigned to me is always prompt, cleans up and makes delicious food 🙂 Would recommend!\n" +
        "\n" +
        "Amandeep was great - came on time - very flexible with the kitchen and easily finds things out - he is very good at making tasty North Indian style meals",
        "Amandeep",
        "/chef/dFXISQywIVD8DWRpBOnr"
    ),
    new Testimonial(
        "Pannaga",
        "Divya is very passionate about cooking and is committed to presenting tasty food to her customers. Also, she knows a variety of South Indian dishes and is open to learning new things to suit the needs of her customers.",
        "Divya",
        "/chef/sZWgmtuiEorJ14QQ7Lqj"
    ),
    new Testimonial(
        "Jyotsna",
        "Geeta is wonderful at cooking Indian food. Highly recommend Chef Geeta! She catered to our dietary needs and preferences, creating delicious chicken Masala, Amritsari Chole, and pepper k with perfect spice levels and less carbs. We'll definitely book her again!",
        "Geeta",
        "/chef/vgtpMGaJYYqeXCFWdzLq"
    ),
    new Testimonial(
        "Mekhala",
        "He was so polite and lovely, not overly talkative. He was very mindful of being in someone else’s space and being respectful. I should have told him to use little oil, but that’s my bad. It would be great if chefs could bring some of their own spices and tools. Jeevan had what he needed that I didn’t have, and that was so helpful.",
        "Jeevan",
        "/chef/94gsgKMXVRCboyWdxgFS"
    ),
];

const googleReviewLink = "https://g.co/kgs/aNSEFv7";

export {
    testimonialStore,
    googleReviewLink
};