import React, { useState, useEffect } from 'react';
import { Button, CircularProgress, Container, Typography, Alert } from '@mui/material';
import {saveChefAndCreateStripeAccount} from "../../firebase/initFirebase";

function SubmitAndRedirect({ formData, address }) {
    const [loading, setLoading] = useState(true);
    const [stripeUrl, setStripeUrl] = useState('');
    const [chefId, setChefId] = useState('');
    const [error, setError] = useState('');
    const [operationComplete, setOperationComplete] = useState(false);


    const saveDetailsAndFetchStripeUrl = async function() {
        setLoading(true);
        setError('');

        try {
            const data = await saveChefAndCreateStripeAccount(formData, address);
            setStripeUrl(data.stripe_url);
            setChefId(data.chef_id);
        } catch (error) {
            console.error("Error creating chef in firebase: ", error);
            setError("We're having trouble processing your account. Please contact support");
        } finally {
            setOperationComplete(true);
            setLoading(false);
        }
    }
    useEffect(() => {
        saveDetailsAndFetchStripeUrl();
    }, [formData, address]); // Ensure formData and address are stable or appropriately memoized

    if (loading) {
        return (
            <Container component="main" maxWidth="sm" style={{ textAlign: 'center' }}>
                <CircularProgress />
            </Container>
        );
    }

    if (error) {
        return (
            <Container component="main" maxWidth="sm">
                <Alert severity="error">{error}</Alert>
                <Button variant="outlined" color="primary" onClick={saveDetailsAndFetchStripeUrl}>
                    Retry
                </Button>
            </Container>
        );
    }

    return (
        <Container component="main" maxWidth="sm">
            <Typography variant="h5" gutterBottom>
                {operationComplete ? "We're almost done!" : 'Processing your information...'}
            </Typography>
            {operationComplete && (
                <>
                    <Typography variant="body1" gutterBottom>
                        Chefs and Home partners with Stripe to securely collect your payment and tax details.
                    </Typography>
                    <Button variant="contained" color="primary" onClick={() => window.location.href = stripeUrl}>
                        Proceed to Stripe
                    </Button>
                </>
            )}
        </Container>
    );
}

export default SubmitAndRedirect;
