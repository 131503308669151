import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getFunctions, httpsCallable } from "firebase/functions";
import {
  addDoc,
  collection,
  doc,
  getFirestore,
  setDoc,
} from "firebase/firestore"; // Import necessary Firestore functions
import { v4 as uuidv4 } from "uuid";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

function hashUUID(uuid) {
  let hash = 0;

  for (let i = 0; i < uuid.length; i++) {
    const char = uuid.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32bit integer
  }

  // Ensure the hash is a 5-digit number
  return Math.abs(hash) % 100000;
}

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();
const db = getDatabase(app);
const firestore = getFirestore(app);
const functions = getFunctions(app);
const firebaseFunctionCreateCustomerThread = httpsCallable(functions, 'notifyConfirmedOrder');
const firebaseFunctionRefreshStripeAccountLink = httpsCallable(functions, 'refreshStripeAccountLink');
const firebaseFunctionSaveChefAndCreateStripeAccount = httpsCallable(functions, 'saveChefAndCreateStripeAccount');


function splitOtherText(input) {
  const delimiters = /[,;\/\s]+/;
  return input.split(delimiters).filter(Boolean);  // Using filter(Boolean) to remove any empty strings
}

function formatChefData(chefData, address) {
  if (chefData.customLanguages) {
    const customLangs = splitOtherText(chefData.customLanguages);
    chefData.languages = chefData.languages.filter(o => o !== "Other");
    Array.prototype.push.apply(chefData.languages, customLangs);
  }
  let cuisine = chefData.cuisines.filter(o=> o !=="Other").join(", ");
  if (chefData.customCuisines) {
    const customCuisines = splitOtherText(chefData.customCuisines);
    cuisine += ", " + customCuisines.join(", ");
  }
  const formattedChefData = {
    ...chefData,
    profile: {
      pronouns: chefData.pronouns,
      pets: chefData.petComfort,
    },
    cuisine: cuisine,
    years: chefData.yearsExperience,
    zipcodes_served: [address.zip],
    otherLanguage: chefData.customLanguages,
    created_at: new Date(),
  };
  delete formattedChefData.pronouns;
  delete formattedChefData.petComfort;
  delete formattedChefData.yearsExperience;
  delete formattedChefData.cuisines;
  delete formattedChefData.customCuisines;
  return formattedChefData;
}

function createStripeData(address) {
  const {returnUrl, refreshUrl} = getStripeUrls("");
  return {
    refresh_url: refreshUrl,
    return_url: returnUrl,
    address: address.toStripeFormat(),
  }
}

async function saveChefAndCreateStripeAccount(chefData, address) {
  try {
    const formattedChefData = formatChefData(chefData, address);
    const stripeData = createStripeData(address);
    const result = await firebaseFunctionSaveChefAndCreateStripeAccount({
      chef_data: formattedChefData,
      stripe_data: stripeData
    });
    return result.data;
  } catch (error) {
    console.error("Error adding new chef:", error);
    throw error;
  }
}
async function uploadChefProfilePhoto(firestore, chef) {

}

async function createOrderInOrders(firestore, currentUserId, orderData) {
  // Add order information to the 'customer_orders' collection
  const OrderRef = await addDoc(
    collection(firestore, "customer_orders"),
    orderData,
  );

  // Return references or any other relevant information
  return { OrderRef };
}

async function createOrderInUIDOrders(firestore, currentUserId, orderData) {
  // Add order information to the 'uid_orders' collection
  const uidOrdersRef = await addDoc(
    collection(firestore, "uid_orders", currentUserId, "orders"),
    orderData,
  );

  // Return references or any other relevant information
  return { uidOrdersRef };
}
async function createDefaultFeedback(currentUserId, feedback) {
  const customDocRef = doc(
    collection(firestore, "customer_feedback"),
    feedback.order_id,
  );

  await setDoc(customDocRef, feedback);
  console.log("added feedback");
  return { customDocRef };
}

async function createCustomerThread(mobile) {
  const formattedPhoneNumber = mobile.charAt(0) === "+" ? mobile : "+1" + mobile;
  const data = {
    phoneNumber: formattedPhoneNumber,
  };
  return firebaseFunctionCreateCustomerThread(data)
      .then((result) => {
        console.log(result.data); // Data returned from cloud function
      })
      .catch((error) => {
        console.error('Error calling function:', error);
      });
}

const createOrderAndRedirect = (
  hours,
  comments,
  date,
  orderAddress,
  apartmentDetails,
  mobile,
  pref,
  otherDetails,
  otherPackageDetails,
  hasAllergy,
  allergyDetails,
  name,
  selectedTimeSlot,
  typeOfOrder,
  howDidYouHear,
) => {
  // console.log(otherPackageDetails)

  return new Promise(async (resolve, reject) => {
    const currentUser = auth.currentUser;
    const currentUserName = name;
    const currentUserEmail = auth.currentUser.email;
    const currentDate = new Date();
    const uniqueId = uuidv4();
    console.log(typeOfOrder);
    console.log(hours);
    try {
      if (typeOfOrder === "Home") {
        if (hours === 1) {
          hours = "3 Dishes, 5-6 servings each";
        } else if (hours === 2) {
          hours = "4 Dishes, 5-6 servings each";
        } else {
          hours = "Custom Order";
        }
      }

      // console.log("in current user");
      const orderData = {
        uid: currentUser.uid,
        type: typeOfOrder,
        food_preference: pref,
        food_preference_details: otherDetails,
        hours: hours,
        hours_details: otherPackageDetails,
        time_slot: selectedTimeSlot?.toString() || "",
        allergy: hasAllergy,
        allergy_details: allergyDetails,
        address: orderAddress,
        apartment_details: apartmentDetails,
        cell: mobile,
        follow_up_preference: "unset",
        comments: comments,
        date: date.toISOString(),
        create_date: currentDate.toISOString(),
        customer_name: currentUserName,
        customer_email: currentUserEmail,
        number: hashUUID(uniqueId),
        status: "Requested",
        created_by: "customer",
        cloned: false,
        date_fulfilled: date.toISOString().split("T")[0] || "",
        how_did_you_hear: howDidYouHear,
      };

      const { uidOrdersRef } = await createOrderInUIDOrders(
        firestore,
        currentUser.uid,
        orderData,
      );

      orderData["uid_orders_ref"] = uidOrdersRef.id;
      const { OrderRef } = await createOrderInOrders(
        firestore,
        currentUser.uid,
        orderData,
      );

      await createCustomerThread(mobile);
      window.location.href = `${window.location.origin}/order/${uidOrdersRef.id}`;

      resolve();
    } catch (error) {
      console.error("Error creating order:", error);
      alert(`Error creating order ${error}`);
      reject();
    }
  });
};

function getStripeUrls(stripeId) {
  const returnUrl = window.location.origin + "/new-chef/stripe-return";
  const refreshUrl = window.location.origin + "/new-chef/stripe-refresh?stripe_id=" + stripeId;
  return {returnUrl, refreshUrl};
}

async function refreshStripeAccountLink(stripeId) {
  const {returnUrl, refreshUrl} = getStripeUrls(stripeId);

  const data = {
    return_url: returnUrl,
    refresh_url: refreshUrl,
    account_id: stripeId,
  }
  return firebaseFunctionRefreshStripeAccountLink(data)
      .then((result) => result.data)
      .catch((error) => {
        console.error('Error calling function:', error);
      });
}



export {
  app,
  auth,
  provider,
  db,
  createOrderAndRedirect,
  firestore,
  createDefaultFeedback,
  refreshStripeAccountLink,
  saveChefAndCreateStripeAccount
};
